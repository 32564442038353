<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-0">
					<div class="text-center">
						<h4 class="mb-3">{{title}}</h4>
						<p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{ desc3 }}</p>
						<p v-b-modal.modal-3 variant="link" class="mb-0"><u>랭킹 모니터링 상품 추가 방법</u></p>
						<div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
						<b-modal id="modal-3" size="xl" title="쿠팡 링크추적 상품 추가 방법" ok-only>
							<p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(랭킹)" 버튼을 클릭한 후 모니터링할 키워드를 입력해 주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
							<img style="max-width:100%;" :src="require('../../../assets/images/small/랭킹모니터링추가.png')">
						</b-modal>
					</div>
				</div>
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data text-center">
								<th>
									<div class="checkbox d-inline-block">
										<input type="checkbox" class="checkbox-input" id="checkbox2" v-model="checkalllists" @change="checkalllist">
										<label for="checkbox2" class="mb-0"></label>
									</div>
								</th>
								<th style="min-width:120px;overflow:hidden;text-overflow: ellipsis;">상품정보</th>
								<th style="max-width:80px;">키워드</th>
								<th style="max-width:80px;">랭킹변화</th>
								<th style="max-width:60px;" v-for="(date, dateIdx) in dates" :key="dateIdx">{{date}}</th>
								<th class="border-right">Actions</th>
							</tr>
						</template>
						<template v-slot:body>
							<tr v-for="(saleslist, index) in saleslists" :key="index">
								<td class="text-center">
									<div class="checkbox d-inline-block">
										<input type="checkbox" class="checkbox-input" id="checkbox2" v-model="saleslist.checked">
										<label for="checkbox2" class="mb-0"></label>
									</div>
								</td>
								<td v-b-modal="modalID(index)" style="min-width:120px;overflow:hidden;text-overflow: ellipsis;">
									<div class="d-flex align-items-center">
										<img :src="saleslist.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
										<div>
										{{saleslist.판매상품명}}
										<p class="mb-0"><small>노출ID: {{saleslist.productId}}</small></p>
										</div>
								</div>
								</td>
								<td>
									<div v-for="(keyword, kIdx) in saleslist.키워드" :key="kIdx" 
										style="max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
										@mouseover="highlightRow(index, kIdx)" @mouseleave="unhighlightRow">
										<span :class="{'highlight': isHighlighted(index, kIdx)}">{{keyword}}</span>
									</div>
									<div v-if="showTraffic" class="badge bg-success">조회수</div>
									<!-- <div v-if="showTraffic" class="badge bg-success">판매량</div> -->
								</td>
								<td style="max-width:60px;font-size:120%" class="text-center">
									<div v-for="(rank, rIdx) in saleslist.랭킹변화" :key="rIdx" 
										:class="[rank > 0 ? 'text-primary' : rank < 0 ? 'text-secondary' : '', {'highlight': isHighlighted(index, rIdx)}]"
										@mouseover="highlightRow(index, rIdx)" @mouseleave="unhighlightRow">
										{{rank === 0 ? "-" : rank}}
										<span class="text-primary" v-if="rank > 0">▲</span>
										<span class="text-secondary" v-if="rank < 0">▼</span>
									</div>
									<div v-if="showTraffic">-</div>
									<!-- <div v-if="showTraffic">판매량</div> -->
								</td>
								<td v-for="(date, dIdx) in dates" :key="dIdx" class="text-center" style="max-width:60px;">
									<!-- <div><br></div> -->
									<div v-for="(keywordRank, kIdx) in saleslist.순위" :key="kIdx"
										:class="{'highlight': isHighlighted(index, kIdx)}">
										{{keywordRank[dIdx] !== undefined ? keywordRank[dIdx] : '-'}}
									</div>
									<div v-if="showTraffic" class="text-success fw-bolder">
										{{saleslist.조회수[dIdx] !== undefined ? saleslist.조회수[dIdx] : '-'}}
									</div>
									<!-- <div v-if="showTraffic">판매량</div> -->
								</td>
								<td>
									<div v-for="(keyword, kIx) in saleslist.키워드" :key="kIx" 
										style="max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
										@mouseover="highlightRow(index, kIx)" @mouseleave="unhighlightRow">
										<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
											href="javascript:void(0)" @click="deletelist(saleslist,keyword)"><i class="ri-delete-bin-line mr-0"></i></a>
									</div>
									<!-- <div>											
										<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete"
											href="javascript:void(0)" @click="deletelist(i)"><i class="ri-delete-bin-line mr-0"></i></a>
									</div> -->
								</td>
									<b-modal :id="'modal'+index" size="xl" scrollable title="랭킹세부이력" ok-title="OK" cancel-title="Close" class="detail-modal" style="font-size:80%">
										<div class="row">
											<div class="col-12">
											<a :href="saleslist.url" target="_blank">
												<card style="padding:10px;">
												<div class="d-flex align-items-center" style="margin:auto;padding:0px">
													<img :src="saleslist.썸네일" class="img-fluid rounded avatar-120 mr-3" alt="image">
													<div>
													<p>판매상품명 : {{saleslist.판매상품명}}</p>
													<p>노출ID : {{saleslist.productId}}</p>
													</div>
												</div>
												</card>
											</a>
											</div>
											<div class="col-12">
												※ 최대 60일로 표시되며 그 이상 수집하는 경우 오래된 순부터 자동 삭제됩니다.
												<div class="table-responsive">
													<table id="datatable" class="table data-table table-striped dataTable text-center">
														<thead>
															<tr class="ligth">
															<th>항목</th>
															<th v-for="(date, dIdx) in saleslist.상세순위[0].ranks.map(r => r.date)" :key="dIdx">{{date}}</th>
															</tr>
														</thead>
														<tbody>
															<tr v-if="showTraffic">
																<td class="badge bg-success">조회수</td>
																<td v-for="(click, cIdx) in saleslist.조회수" :key="cIdx"  class="text-success fw-bold">
																	{{click}}
																</td>
															</tr>
															<tr v-for="(keywordRanks, kIdx) in saleslist.상세순위" :key="kIdx">
															<td>{{saleslist.키워드[kIdx]}}</td>
															<td v-for="(rank, rIdx) in keywordRanks.ranks" :key="rIdx">
																{{rank.rank}}
																<span :class="rank.change >= 0 ? 'text-primary' : 'text-secondary'">
																({{rank.change === 0 ? "-" : rank.change}})
																</span>
															</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</b-modal>
							</tr>
						</template>
					</tableList>
				</div>
				<b-button variant="primary" class="mr-2 mb-4" @click="collectRank">현재랭킹수집</b-button>
				<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
				<button type="button" class="btn btn-success mr-2 mb-4" @click="download">다운로드</button>
				
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import { core } from '../../../config/pluginInit';

export default {
	name: 'ranking',
	data() {
		return {
			showTraffic: false,
			highlightedRow: null,
			highlightedKeyword: null,
			salesarr: [],
			importarr: [],
			stockarr: [],
			data: [],
			checkalllists: false,
			loadingtext: '데이터 불러오는 중 ...',
			title: "랭킹 추적",
			desc1: "랭킹 추적할 상품을 확장프로그램에서 추가해주세요.",
			desc2: "랭킹은 00시부터 하루 1회 순차적으로 수집됩니다.",
			desc3: "베이직버전은 30슬릇까지, 프리미엄버전은 무제한슬롯으로 추가가 가능합니다.",
			showspinner: false,
			saleslists: [],
			saleslistsinit: [],
			showlist: false,
			dates: [],
			datesinit: [],
			isMultipleDelete: false,
			deleteQueue: [],
			deleteTimeout: null,
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	mounted() {
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'keywordanalysisstatusratio'){
                console.log(event.data.status);
				this.showspinner = false;
				this.loadingtext = event.data.text + '...(' + event.data.status + ")";
				this.$nextTick(() => {
					this.showspinner = true;
				})
            }
			if(event.data.res && event.data.res === 'collectRankResult'){
				console.log(event.data);
				var ele = event.data.result;
				const index = this.data.findIndex(e => e.productId == ele.productId && ele.keyword == e.keyword);
				if (index !== -1) {
					this.data[index] = ele;
					this.showlist = false;
					this.$nextTick(() => {
						this.searchresult();
					})
				}
			}
		}
		window.addEventListener('message', this.messageEventHandler);
		this.$nextTick(function () {
			if(this.$store.state.user.auth.showsales){
				this.showTraffic = true;
			}
			// if(this.$store.state.user.auth.showsales){
			// 	this.desc2 = "프리미엄버전의 랭킹추적은 확장프로그램을 열고 Ctrl+Shift+L 단축키를 실행해서 수집가능합니다."
			// }
			this.search();
			
		});
	},
	methods: {
		getToday(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + month + day;
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		download(){
			console.log(this.data)
			var dates = []
			this.data.forEach(e => {
				e.ranklist.forEach(f => {
					if(!dates.includes(f.date)){
						dates.push(f.date)
					}
				})
			})
			console.log(dates)
			dates.sort((a,b)=>{return b-a})
			var arr = [];
			this.data.forEach(e => {
				var obj = {
					상품명 : e.itemName,
					노출ID : e.productId,
					옵션ID : e.vendorItemId,
					키워드 : e.keyword,
				}
				dates.forEach(d => {
					console.log(d)
					obj[d] = e.ranklist.find(f => f.date == d) ? e.ranklist.find(f => f.date == d).totalrank : ""
				})
				console.log(obj)
				arr.push(this.deepClone(obj))
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '랭킹추적현황')
			XLSX.writeFile(workBook, '랭킹추적현황' + '_' + this.getToday() + '.xlsx')
		},
		collectRank(){
			console.log(this.saleslists)
			console.log(this.data)
			if(this.saleslists.length == 0){
				alert('수집된 상품이 없습니다. 확장프로그램에서 상품을 먼저 수집해 주세요')
				return
			}

			if(confirm('수집되지 않은 키워드만 수집하시겠습니까?')){
				var collectKeyword = true;
			} else {
				collectKeyword = false;
			}

			var today = this.getTodaywith();
			var list = [];
			var filteredArr = [];
			if(this.saleslists.filter(e => e.checked).length == 0){
				if(!confirm('전체 상품의 랭킹을 수집하시겠습니까?' + '\n' + '새탭에 쿠팡윙이 로그인되어 있어야 조회수가 수집됩니다.' + '\n' + 'ip차단 방지를 위해 반드시 휴대폰테더링으로 접속해 주세요.')){
					return
				} else {
					if(collectKeyword){
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								if(this.data.filter(e => e.ranklist[e.ranklist.length-1].date !== today).find(e => e.productId == f.productId && e.keyword == ele)){
									list.push(this.data.filter(e => e.ranklist[e.ranklist.length-1].date !== today).find(e => e.productId == f.productId && e.keyword == ele));
								}
							})
						})
						filteredArr = this.data.filter(e => e.ranklist[e.ranklist.length-1].date !== today);
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								if(filteredArr.find(e => e.productId == f.productId && e.keyword == ele)){
									list.push(filteredArr.find(e => e.productId == f.productId && e.keyword == ele));
								}
							})
						})
					} else {
						filteredArr = this.data;
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								if(filteredArr.find(e => e.productId == f.productId && e.keyword == ele)){
									list.push(filteredArr.find(e => e.productId == f.productId && e.keyword == ele));
								}
							})
						})
						list = this.data;
					}
				}
			} else {
				if(!confirm(this.saleslists.filter(e => e.checked).length + '개 상품의 랭킹을 수집하시겠습니까?' + '\n' + '새탭에 쿠팡윙이 로그인되어 있어야 조회수가 수집됩니다.' + '\n' + 'ip차단 방지를 위해 반드시 휴대폰테더링으로 접속해 주세요.')){
					return
				} else {
					var arr = this.saleslists.filter(e => e.checked);
					var productIds = arr.map(e => e.productId);
					if(collectKeyword){
						filteredArr = this.data.filter(e => productIds.includes(e.productId) && (e.ranklist[e.ranklist.length-1].date !== today));
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								if(filteredArr.find(e => e.productId == f.productId && e.keyword == ele)){
									list.push(filteredArr.find(e => e.productId == f.productId && e.keyword == ele));
								}
							})
						})
					} else {
						filteredArr = this.data.filter(e => productIds.includes(e.productId));
						this.saleslists.forEach(f => {
							f.키워드.forEach(ele => {
								if(filteredArr.find(e => e.productId == f.productId && e.keyword == ele)){
									list.push(filteredArr.find(e => e.productId == f.productId && e.keyword == ele));
								}
							})
						})
					}
				}
			}

			window.postMessage({ greeting: "collectRank", companyid: this.$store.state.user.companyid, rankArr:list}, "*",)
            this.showspinner = true;
		},
		highlightRow(rowIndex, keywordIndex) {
			this.highlightedRow = rowIndex;
			this.highlightedKeyword = keywordIndex;
		},
		unhighlightRow() {
			this.highlightedRow = null;
			this.highlightedKeyword = null;
		},
		isHighlighted(rowIndex, keywordIndex) {
			return this.highlightedRow === rowIndex && this.highlightedKeyword === keywordIndex;
		},
		checkalllist() {
			// if(confirm('현재 페이지만 선택하시겠습니까? 확인:현재페이지만 선택, 취소:전체선택')){
			// 	for(var i=0;i<document.querySelector('.ligth-body').children.length;i++){
			// 		var barcode = parseInt(document.querySelector('.ligth-body').children[i].children[1].children[0].children[1].children[0].innerText.replace('노출ID: ',''));
			// 		this.saleslists.find(e => e.productId == barcode).checked = this.checkalllists;
			// 	}
			// 	return
			// }
			if (this.checkalllists) {
				this.saleslists.forEach(e => {
					e.checked = true;
				});
			} else {
				this.saleslists.forEach(e => {
					e.checked = false;
				});
			}
		},
		deletelist(saleslist, keyword) {
			if (!this.isMultipleDelete) {
			// 최초 실행 시 사용자에게 삭제 모드를 묻습니다.
			const choice = confirm("여러 키워드를 연속으로 삭제하시겠습니까? '확인'을 누르면 연속 삭제 모드로 전환됩니다.");
			this.isMultipleDelete = choice;
			if (!choice) {
				// 단일 삭제 모드
				if (confirm(`"${keyword}" 키워드를 삭제하시겠습니까?`)) {
				this.performDelete(saleslist, keyword);
				}
				return;
			}
			}
			
			// 연속 삭제 모드
			this.performDelete(saleslist, keyword);
		},

		performDelete(saleslist, keyword) {
			// 데이터 테이블에서 키워드 삭제
			const index = this.saleslists.findIndex(item => item.productId === saleslist.productId);
			if (index !== -1) {
			const keywordIndex = this.saleslists[index].키워드.indexOf(keyword);
			if (keywordIndex !== -1) {
				this.saleslists[index].키워드.splice(keywordIndex, 1);
				this.saleslists[index].랭킹변화.splice(keywordIndex, 1);
				this.saleslists[index].순위.splice(keywordIndex, 1);
				this.saleslists[index].상세순위 = this.saleslists[index].상세순위.filter(item => item.keyword !== keyword);
			}
			// 만약 키워드가 모두 삭제되면 해당 상품도 제거
			if (this.saleslists[index].키워드.length === 0) {
				this.saleslists.splice(index, 1);
			}
			}

			// 삭제 큐에 추가
			this.deleteQueue.push({ productId: saleslist.productId, 키워드: keyword });

			// 삭제 요청을 서버로 전송 (디바운스 적용)
			this.debouncedDeleteRequest();
		},

		debouncedDeleteRequest() {
			clearTimeout(this.deleteTimeout);
			this.deleteTimeout = setTimeout(() => {
				if (this.deleteQueue.length > 0) {
				axios.post('/api/analysis/deletetrackingrank', this.deleteQueue)
					.then(result => {
					if (result.data.res === 'OK') {
						console.log('삭제 성공');
						this.deleteQueue = []; // 큐 초기화
					} else {
						alert('서버 삭제 실패. 다시 시도해주세요.');
						console.log(result);
					}
					})
					.catch(error => {
					console.error('삭제 요청 중 오류 발생:', error);
					alert('삭제 중 오류가 발생했습니다. 다시 시도해주세요.');
					});
				}
			}, 1000); // 1초 딜레이
		},
		deleteitem() {
			console.log(this.saleslists.filter(e => e.checked))
			if (this.saleslists.filter(e => e.checked).length === 0) {
				alert('삭제할 항목을 선택해 주세요');
			} else {
				var arr = [];
				for(var saleslist of this.saleslists.filter(e => e.checked)){
					for(var e of saleslist.키워드){
						arr.push({
							productId : saleslist.productId,
							키워드 : e
						})
					}
				}
				console.log(arr)
				if (confirm(this.saleslists.filter(e => e.checked).length + '개의 항목을 삭제하시겠습니까?')) {
					axios.post('/api/analysis/deletetrackingrank', arr)
						.then(result => {
							if (result.data.res === 'OK') {
								this.search();
							} else {
								alert('삭제실패');
								console.log(result);
							}
						});
				}
			}
		},
		modalID(index) {
			return 'modal' + index;
		},
		search() {
			this.showspinner = true;
			this.showlist = false;
			axios.post('/api/analysis/getranking', { companyid: this.$store.state.user.companyid })
				.then(result => {
					if (result.data.length > 0) {
						this.data = result.data;
						this.searchresult();
					} else {
						alert('추가된 모니터링 상품이 없습니다.');
						return;
					}
				});
		},
		searchresult() {
			this.dates = [];
			this.datesinit = [];
			for (let i = 0; i < 8; i++) {
				let day = new Date(this.getdday(i));
				const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];
				let week = WEEKDAY[day.getDay()];
				let output = this.getdday(i).slice(5) + " (" + week + ")";
				this.dates.push(output);
				this.datesinit.push(this.getdday(i));
			}

			const groupedData = this.data.reduce((acc, cur) => {
				if (!acc[cur.productId]) {
					acc[cur.productId] = {
						썸네일: cur.image,
						판매상품명: cur.itemName,
						productId: cur.productId,
						// url: `https://www.coupang.com/vp/products/${cur.productId}?itemId=${cur.itemId}&vendorItemId=${cur.vendorItemId}`,
						url: `https://www.coupang.com/vp/products/${cur.productId}`,
						키워드: [],
						랭킹변화: [],
						순위: [],
						상세순위: [],
						조회수: [],
					};
				}
				if (!acc[cur.productId].키워드.includes(cur.keyword)) {
					acc[cur.productId].키워드.push(cur.keyword);
				}
				return acc;
			}, {});

			Object.values(groupedData).forEach(item => {
				item.순위 = [];
				item.랭킹변화 = [];
				item.상세순위 = [];

				// 조회수 데이터 처리 (키워드와 독립적)
				const clickData = this.data.find(d => d.productId === item.productId)?.ranklist || [];
				item.조회수 = this.datesinit.map(date => {
					const clickItem = clickData.find(r => r.date === date);
					return clickItem?.click || '-';
				});

				item.키워드.forEach(keyword => {
					const rankData = this.data.filter(d => d.productId === item.productId && d.keyword === keyword);
					const ranks = this.datesinit.map(date => {
						const rankItem = rankData.find(d => d.ranklist.find(r => r.date === date))?.ranklist.find(r => r.date === date);
						return rankItem?.totalrank !== undefined ? rankItem.totalrank : '-';
					});
					item.순위.push(ranks);

					const rankChanges = ranks.slice(1).map((rank, idx) => {
						const prevRank = ranks[idx];
						if (rank === '-' || prevRank === '-') return 0;
						return rank - prevRank;
					});
					item.랭킹변화.push(rankChanges[0] || 0);

					const detailRanks = rankData[0].ranklist.map(r => ({
						date: r.date,
						rank: r.totalrank,
						change: 0,
					})).reverse();
					detailRanks.forEach((r, idx) => {
						if (idx < detailRanks.length - 1) {
							r.change = detailRanks[idx + 1].rank - r.rank;
						}
					});
					item.상세순위.push({ keyword, ranks: detailRanks });
				});
			});

			this.saleslists = Object.values(groupedData);
			console.log(this.saleslists)
			console.log(this.data)

			// if (this.saleslists.length === this.data.length) {
				setTimeout(async () => {
					this.showlist = true;
					this.showspinner = false;
					const res = await core.checkpage();
					console.log(res);
				}, 10);
			// }
		},
		getdday(i) {
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000 * 60 * 60 * 24 * i);
		},
		getTodaywith() {
			const date = new Date();
			const year = date.getFullYear();
			const month = ("0" + (1 + date.getMonth())).slice(-2);
			const day = ("0" + date.getDate()).slice(-2);
			return `${year}-${month}-${day}`;
		},
		getDatewith(d) {
			const date = new Date(d);
			const year = date.getFullYear();
			const month = ("0" + (1 + date.getMonth())).slice(-2);
			const day = ("0" + date.getDate()).slice(-2);
			return `${year}-${month}-${day}`;
		},
		datetotimestamp(date) {
			return new Date(date).getTime();
		},
	},
};
</script>

<style scoped>
.highlight {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 4px;
}
</style>